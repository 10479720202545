export function register() {
  if (navigator.serviceWorker) {
    window.addEventListener(`load`, () => {
      const url = `${process.env.CONFIG_SERVICE_URL}/sw.js`;
      navigator.serviceWorker
        .register(url, {scope: './'})
        .then(registration => {
          registration.addEventListener('updatefound', () => {
            const newWorker = registration.installing;

            newWorker.addEventListener('statechange', () => {
              switch (newWorker.state) {
                case `installed`:
                  if (navigator.serviceWorker.controller) {
                    console.log(`Worker Status`, newWorker.state);
                    window.localStorage.setItem(`shouldUpdateServiceWorker`, `true`);
                    newWorker.postMessage({ action: 'skipWaiting' })
                  }
                  break;
                default:
                  break;
              }
            })
          });
        })
        .catch(err => {
          console.error(`Registration failed:`, err);
        });
    });
  } else {
    console.log(`Service Worker is not supported in this browser.`);
  }
}
