export function prefix(location, ...prefixes) {
  return prefixes.some(
    prefix => (
      location.href.indexOf(`${location.origin}/${prefix}`) !== -1
    )
  )
}

export function hub(location) {
  if (location.href.indexOf(`${location.origin}/quality`) === -1) {
    return true; 
  }
  return false;
}

export function quality(location) {
  return prefix(location, 'quality')
}
